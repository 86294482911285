<template>
  <div>
    <div class="md-card">
      <div class="md-card-header">
        <div class="card-icon">
          <i class="fa fa-globe"></i>
        </div>
        <h4 class="title">{{ getTitle() }}</h4>
      </div>
      <div class="md-card-content">
        <div class="md-layout-item">
          <technician-form></technician-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ManagementTechnicanPage from "./ManageTechnicianPage.vue";
export default {
  inject: ["manageTechnician", "progress"],
  components: {
    "technician-form": ManagementTechnicanPage,
  },
  methods: {
    getTitle() {
      if (this.manageTechnician.page == "Approval")
        return "Technician Approval";
      else if (this.manageTechnician.id) return "Edit Technician";
      else return "New Technician";
    },
  },
};
</script>
